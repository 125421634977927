import { Component, BaseComponent, Prop, VariationBase } from '@zento-lib/components';
import onEscapePressMixin from '@vue-storefront/core/mixins/onEscapePress';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import type { IHeaderItem } from './HeaderItem.d';

export type IconName =
  | 'hamburger'
  | 'hamburger-white'
  | 'search'
  | 'search-white'
  | 'account'
  | 'account-white'
  | 'wishlist'
  | 'wishlist-white'
  | 'cart'
  | 'cart-white'
  | 'customer-service'
  | 'no-icon'
  | 'close'
  | 'plus';

/**
 * Header Item
 *
 * Header Item component allowing to create icon and label.
 **/
@Component({
  mixins: [onEscapePressMixin],
})
@VariationBase
export class HeaderItem extends BaseComponent<IHeaderItem, unknown> {
  protected static V = {
    icon: () => import('./variations/icon').then((m) => m.Icon),
    label: () => import('./variations/label').then((m) => m.Label),
    labelIcon: () => import('./variations/labelIcon').then((m) => m.LabelIcon),
  };

  /**
   * Handle escape.
   */
  @Prop({ type: Function, default: () => undefined })
  onEscape?: () => void;

  /**
   * Handle interaction.
   */
  @Prop({ type: Function, default: () => undefined, required: true })
  onPress: (ev: Event) => void;

  /**
   * Data testid.
   */
  @Prop({ type: String, default: undefined })
  testId?: string;

  /**
   * Icon.
   */
  @Prop({ type: String, default: 'no-icon' })
  icon?: IconName;

  /**
   * A name for the control.
   */
  @Prop({ type: Object, default: undefined })
  label?: I18nMessage;

  /**
   * Cart count.
   */
  @Prop({ type: Number })
  count?: number;

  protected onItemPress(ev) {
    if (this.icon !== 'plus') {
      document.body.style.overflow = 'hidden';
    }

    if (this.onPress) {
      this.onPress(ev);
    }
  }

  protected onEscapePress() {
    if (this.onEscape) {
      this.onEscape();
    }

    document.body.style.overflow = 'visible';
  }

  beforeMount() {
    this.onItemPress = this.onItemPress.bind(this);
  }
}
